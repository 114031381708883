<template>
  <div id="page-header">
    <div class="header_left">
      <div class="app_logo">
        <img :src="logSrc" alt="logo" />
        <span v-if="show_admin_platform">
          <!-- 管理控制台 -->
          {{ $t('header.admin_console') }}
        </span>
        <span v-if="!show_admin_platform">
          <!-- 应用中心 -->
          {{ $t('header.apps') }}
        </span>
      </div>
    </div>
    <div class="header_right">
      <!-- 添加具名插槽 -->
      <slot name="headerContent">
        <div
          v-if="user_type === 2 || user_type === 4"
          class="manage_router"
          @click="gotoAppPath"
        >
          <i class="el-icon-setting"></i>
          <!-- 应用门户 -->
          {{ $t('header.portal') }}
        </div>
      </slot>
      <el-divider class="header_divider" direction="vertical"></el-divider>
      <el-dropdown
        trigger="click"
        @command="handleDropdownCommand"
        :hide-on-click="false"
      >
        <span class="el-dropdown-link">
          <img src="@/assets/images/header/avatar.png" alt="头像" />
          <div>{{ display_name }}</div>
          <i class="el-icon-caret-bottom"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="user_info_dropdown">
          <div class="user_card">
            <img src="@/assets/images/header/avatar.png" alt="头像" />
            <div class="user_enterprise">
              <div class="user">
                {{ display_name }}
              </div>
              <div class="enterprise">{{ enterpriseName }}</div>
            </div>
          </div>
          <el-dropdown-item command="profile" class="user_info_dropdown_item">
            {{ $t('header.user_info') }}
          </el-dropdown-item>

          <el-dropdown
            trigger="click"
            placement="left-start"
            @command="changeLanguage"
            class="language_dropdown"
          >
            <el-dropdown-item class="language_trigger" command="null">
              <!-- 语言切换 -->
              {{ $t('header.language_switch') }}
              <div class="language_trigger_right">
                <i class="el-icon-arrow-right"></i>
              </div>
            </el-dropdown-item>
            <el-dropdown-menu slot="dropdown" class="language_dropdown_left">
              <el-dropdown-item command="zh-cn" class="language_item">
                简体中文 (zh-cn)
                <i
                  v-if="showLanguage === 'zh-cn'"
                  class="el-icon-check language_selected"
                  style="color: #165dff"
                ></i>
              </el-dropdown-item>
              <!-- 繁体中文 zh-Hant -->
              <el-dropdown-item command="zh-tw" class="language_item">
                繁體中文 (zh-hk)
                <i
                  v-if="showLanguage === 'zh-tw'"
                  class="el-icon-check language_selected"
                  style="color: #165dff"
                ></i>
              </el-dropdown-item>
              <el-dropdown-item command="en" class="language_item"
                >English (en-us)
                <i
                  v-if="showLanguage === 'en'"
                  class="el-icon-check language_selected"
                  style="color: #165dff"
                ></i>
              </el-dropdown-item>
              <!-- 马来语 -->
              <el-dropdown-item command="ms" class="language_item"
                >Bahasa Melayu (ms)
                <i
                  v-if="showLanguage === 'ms'"
                  class="el-icon-check language_selected"
                ></i>
              </el-dropdown-item>
              <!-- 泰语 -->
              <el-dropdown-item command="th" class="language_item"
                >ไทย (th)
                <i
                  v-if="showLanguage === 'th'"
                  class="el-icon-check language_selected"
                ></i>
              </el-dropdown-item>
              <!-- 孟加拉语 -->
              <el-dropdown-item command="bn" class="language_item"
                >বাংলা (bd)
                <i
                  v-if="showLanguage === 'bn'"
                  class="el-icon-check language_selected"
                ></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown-item command="logout" class="user_info_dropdown_item">
            {{ $t('header.logout') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    user_name: localStorage.getItem('user_name') || ''
  }),
  computed: {
    ...mapGetters(['user_type', 'showLanguage']),
    // 自定义logo
    logSrc() {
      const admin_enterprise_detail = JSON.parse(
        localStorage.getItem('admin_enterprise_detail') || null
      );
      if (admin_enterprise_detail) {
        const logo = admin_enterprise_detail.enterprise_profile;
        if (logo) return logo;
      }
      return require('@/assets/common-admin-logo.svg');
    },
    display_name() {
      let admin_user_info = JSON.parse(
        localStorage.getItem('admin_user_info') || null
      );
      if (admin_user_info) {
        const display_name = admin_user_info.display_name;
        return display_name;
      }
      return '';
    },

    hasPresonalLogo() {
      let admin_enterprise_detail = JSON.parse(
        localStorage.getItem('admin_enterprise_detail')
      );
      if (admin_enterprise_detail) {
        const logo = admin_enterprise_detail.enterprise_profile;
        if (logo) {
          return true;
        }
      }
      // 如果没有enterprise_profile或logoUrl
      return false;
    },

    show_admin_platform() {
      if (this.user_type === 1) {
        return true;
      }
      if (this.user_type === 2 || this.user_type === 4) {
        const { fullPath } = this.$route;
        if (fullPath !== '/portal') {
          return true;
        }
      }
      return false;
    },
    enterpriseName() {
      const admin_enterprise_detail = localStorage.getItem(
        'admin_enterprise_detail'
      )
        ? JSON.parse(localStorage.getItem('admin_enterprise_detail'))
        : {};
      const { enterprise_name } = admin_enterprise_detail;
      if (enterprise_name) {
        return enterprise_name;
      }
      return '';
    }
  },
  methods: {
    clickShowUserPreference() {},

    logout() {
      Cookies.remove('teamsun_token');
      this.$router.push('/login');
    },
    handleDropdownCommand(command) {
      const commandMap = {
        profile: () => this.clickShowUserPreference(), // 个人信息
        logout: () => this.logout() // 登出
      };

      const handler = commandMap[command];
      if (handler && typeof handler === 'function') {
        handler();
      }
    },
    changeLanguage(command) {
      console.log(command);
      if (command === 'null') {
        return;
      }
      if (this.showLanguage === command) return;
      this.$i18n.locale = command;
      this.$store.commit('app/setLanguage', command);
      // 切换语种刷新页面
      window.location.reload();
    },
    gotoAppPath() {
      this.$router.push('/portal');
    }
  }
};
</script>

<style lang="scss" scoped>
$page-header-color: #ffffff;

$color-select: #f4f4f4;
$color-select-hover: #9393a2;

$page-menu-width: 180px;
$page-header-height: 50px;

$page-menu-color: #fff;

@mixin header-border-bottom() {
  box-shadow: var(--shadow-primary);
  border-bottom: 1px solid var(--color-divider-normal);
}

#page-header {
  width: 100%;
  height: $page-header-height;
  background: $page-header-color;
  padding: 0px 16px;
  box-sizing: border-box;
  @include header-border-bottom();

  color: #000000;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .app_logo {
    height: $page-header-height - 2px;
    background-color: $page-menu-color;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      max-height: 40px;
      width: auto;
    }
    span {
      font-size: 19px;
      color: #1d2129;
      font-weight: bolder;
      margin-left: 8px;
      font-family: 'PingFang SC';
    }
  }

  .header_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header_divider {
    height: 16px;
    background-color: #e0e0e0;
  }

  #header_desc {
    height: 32px;
    width: 32px;
    background-color: #f2f3f5;
    border-radius: 50%;
    margin: 0px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #eeeeee;
    font-size: 18px;
  }
}

/* 菜单标题文字颜色*/
.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
}

.manage_router {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-right: 16px;
}

// 用户中心下拉
.user_card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  height: 52px;
  img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }

  .user_enterprise {
    flex: 1;
    .user {
      font-size: 14px;
      color: #000000;
      font-weight: 400;
      line-height: 20px;
    }
    .enterprise {
      font-size: 12px;
      line-height: 18px;
      color: #4e5969;
    }
  }
}
.user_info_dropdown {
  width: 250px;
  .user_info_dropdown_item {
    height: 40px;
    line-height: 40px;
    // 最后一个下拉项退出显示边框
    &:last-child {
      border-top: 1px solid #c9cdd4;
    }
  }
  .language_dropdown {
    width: 100%;
    .el-dropdown-menu__item {
      height: 40px;
    }
  }

  .language_trigger {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .language_trigger_right {
      display: flex;
      align-items: center;
      gap: 5px;
      .el-icon-arrow-right {
        font-size: 12px;
        margin-right: -2px;
        position: relative;
        top: 1px;
      }
    }
  }
}

.language_dropdown_left {
  width: 200px;

  .language_item {
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .language_selected {
      font-size: 20px;
      color: #165dff;
    }
  }
}
</style>
